/* HomePage.css */

/* Container for the entire page */
.homepage {
    background-color: rgb(38,71,150); /* Blue background color */
    color: #fff; /* White text color */
    padding: 20px;
    height: 100%; /* Add padding for content spacing */
  }
  
  /* Main content layout */
  .main-content {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space evenly between left and right content */
    flex-wrap: wrap; /* Wrap content to next line if necessary */
    max-width: 1200px; /* Limit maximum width */
    margin: 50px auto; /* Center content horizontally */

  }
  
  /* Left content */
  .right-content {
    flex: 1; /* Take up remaining space */
    padding-left: 0;
    margin-top: 30px;
    /* Add padding to the right for spacing */
  }
  
  /* Right content (Image) */
  .left-content {
    flex: 1; /* Take up remaining space */
    text-align: right; /* Align content to the right */
    /* margin-top: 100px; */
  }
  .left-content img {
    max-width: 80%; /* Set maximum width to 100% of container */
    height: auto;
      margin-right: 20%;

     /* Ensure image maintains aspect ratio */
  }
  .btn {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: rgb(220,189,96); /* Pink button color */
    color: whitesmoke; /* White text color */
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  /* Button hover animation */
  .btn:hover {
    background-color: rgb(160,121,39); 
    color: #fff;/* Darker pink color on hover */
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .main-content {
      flex-direction: column; /* Stack content vertically on smaller screens */
    }
  
    .left-content,
    .right-content {
      flex: none; /* Reset flex property */
      width: 100%; /* Set width to 100% */
      text-align: center; /* Center align content */
    }
  
    .left-content img {
      max-width: 50%;
      margin-right: 0px;
       /* Ensure image doesn't exceed container width */
    }
  }
  