.page-heading {
    text-align: center;
    margin-bottom: 40px;
    color:#004caa !important;
    font-family: "EB Garamond";
    font-size: 36px;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
  
  .service-card {
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    background-color: #004caa;
    color: white;
    border: 1px solid #3672bc; /* Changed to a border */
    border-radius: 5px;
    padding: 0 40px 50px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease;
    position: relative; /* Added to enable positioning of title */
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .icon {
    font-size: 3rem;
    margin: 20px auto;
    color:rgb(220,189,96);
  }
  
  .title {
    text-align: center;
    margin-top: auto; /* Pushes the title to the center */
    font-weight: bold;
  }
  