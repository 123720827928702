.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

}

.doctors {
  margin-top: 30px;
  text-align: center;
  color: #004caa;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: "EB Garamond";
}
.callButton:hover {
  background-color: blue !important;
  color:#fff /* Change to your desired color */
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px; /* Increased the gap between cards */
  margin-top: 50px;
}

.card {
  flex: 1 1 calc(50% - 30px); /* Two cards per row on smaller screens */
  background-color: #ff69b4; /* Updated background color */
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
  transition: transform 0.3s ease;
  margin-bottom: 30px;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.cover {
  width: 120px;
  height: 120px;
  margin: 20px auto;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.name {
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "EB Garamond";
  align-items: center;
}

.degree {
  color: #666;
}

.contact {
  color: #888;
  margin-top: 10px;
}

.reviewContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-family: "EB Garamond";
}

.reviewContainer h2 {
  color: #333;
  font-size: 32px;
}

.reviewContainer p {
  color: #333;
  font-size: 20px;
}
.reviewer-name {
  font-family: 'Dancing Script', cursive; /* Use Dancing Script font */
  font-size: 24px; /* Adjust font size as needed */
  color: #ff69b4; /* Custom color */
  font-weight: bold; /* Make it bold */
  /* Add any other styling you want */
}
.reviewer-name::before {
  content: '- '; /* Add a dash before the name */
}


@media (max-width: 768px) {
  .card {
    flex: 1 1 100%; /* One card per row on smaller screens */
    margin-bottom: 20px; /* Add margin between cards */
  }

  .reviewContainer {
    margin-left: 0; /* Remove left margin for the review container */
  }
}

