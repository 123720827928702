.page-heading {
  text-align: center;
  margin-bottom: 40px;
  color: aliceblue;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.contact-card {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  background-color: #004caa;
  color: white;
  border: 1px solid #3672bc; /* Changed to a border */
  border-radius: 5px;
  padding: 60px 47px 70px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
  position: relative; /* Added to enable positioning of title */
  text-align: center;
}

.contact-card:hover {
  transform: translateY(-5px);
}

.icon {
  font-size: 3rem;
  margin: 20px auto;
  color:rgb(220,189,96);
}

.title {
  text-align: center;
  margin-top: auto; /* Pushes the title to the center */
  font-weight: bold;
  font-family: "EB Garamond";
}

.welcome-section {
  text-align: center;
  margin-top: 30px;
  font-family: "EB Garamond";
}

.welcome-heading {
  color: #004caa;
  font-size: 36px;
  margin-bottom: 20px;
}

.welcome-text {
  color: #333;
  font-size: 18px;
  line-height: 1.6;
  font-family: "EB Garamond";
}
.background-image-container{
  margin-bottom: 20px;
}

.card-text{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  cursor: pointer;
}
.card-title{
  margin-bottom: 0 !important;
}