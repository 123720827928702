/* Navbar.css */

/* Navbar container */
.navbar {
  background-color: #fff; /* White background */
}

/* Navbar brand text */
.navbar-brand {
  font-family: 'EB Garamond', sans-serif;
  font-weight: bold;
  color: #004caa; /* Dark text color */
  margin-left: 20px; /* Add space to the right of the navbar brand */
}

/* Navbar links */
.navbar-nav .nav-link {
  color: rgb(160,121,39); /* Dark text color */
  font-weight: 500; /* Medium font weight */
  margin-right: 20px;

}

/* Navbar links on hover */
.navbar-nav .nav-link:hover {
  color: #007bff; /* Blue color on hover */
}

/* Hamburger icon color */
/* .navbar-toggler-icon-bg{
  color:red;
} */

/* Background color of expanded navbar */
.navbar-collapse {
  background-color: #fff; 
  margin-left: 20px;/* White background */
}

/* Align navbar links to the right */
.navbar-nav {
  margin-left: auto; /* Push navbar links to the right */
}

/* Add margin to navbar toggler for better spacing */
.navbar-toggler {
  margin-right: 5px;
}

.custom-toggler {
  background: none;

  border-color: none; /* Border color of the toggler button */
  padding: 5px; /* Adjust padding as needed */
}

.custom-toggler img {
  width: 30px; /* Adjust width and height of the custom icon */
  height: auto;
  vertical-align: middle;
  
}

/* Additional styling for Navbar links and other elements can be added here */
